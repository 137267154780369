/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
       
        $("#main").attr("tabindex", "-1");
        $("#menu-menu-1").attr("tabindex", "-1");
        $("a").removeAttr("target");

        $("input,a,select,textarea,button").removeAttr("tabindex");

        $("h1").each(function () {
          $(this).attr("id", "lbl-main");
        });

        $('a[href^="mailto"]').each(function () {
          $(this).attr("title", "Enviar e-mail");
        });

        $(".first-button").on("click", function () {
          $(".animated-icon1").toggleClass("open");
        });

        if (
          jQuery("#navbarNav > div > div > a").attr("href") ===
          window.location.href
        ) {
          jQuery("#navbarNav > div > div > a").addClass("active");
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    quem_somos: {
      init: function () {
        $("#accordion").on("hidden.bs.collapse", function (e) {
          $('[data-target="#' + $(e.target).attr("id") + '"]')
            .find(".btn-more")
            .text("+");
        });
        $("#accordion").on("shown.bs.collapse", function (e) {
          $('[data-target="#' + $(e.target).attr("id") + '"]')
            .find(".btn-more")
            .text("-");
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      $(".back-top").click(function (e) {
        e.preventDefault();
        $("body,html").animate(
          {
            scrollTop: 0,
          },
          500
        );
        $("#topo").focus();
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
